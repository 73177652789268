@use 'css/abstracts' as *;
$o-header__logo_w: 200;
$o-header__logo_w--full: 400;
$o-header__head_pd-vertical: 12;
$o-header__head_pdb-vertical: $o-header__head_pd-vertical + 6;
$o-header__head_pd-vertical--full: 20;
$o-header__head_pdb-vertical--full: 0;

.o-header {
	--_narrow-p-r: 64px;
	background-color: var(--global-color-white);
	color: var(--global-color-black);
	// padding: 8px 24px 8px 11px;
	padding: 12px 24px 12px 12px;
	height: 48px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	@media (min-width: 1200px) {
		padding: 12px 24px 12px 40px;
		height: 74px;
	}
	.o-headerNavwrap {
		display: none;
		flex: auto;
		justify-content: flex-end;
		align-items: center;
		margin-left: 24px;
		@media (min-width: 1200px) {
			display: flex;
		}
	}
	.a-headerLogo {
		display: block;
		// width: 90px;
    width: 208px;
		@media (min-width: 1200px) {
			width: 140px;
		}
	}
}
.o-headerNav__list {
	display: flex;
	margin-right: 16px;
}
.o-headerNav__item {
	// border-left: 1px solid #000;
	// width: 118px;
}
.o-headerNav__link {
	color: var(--black, #1c1f1e);
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 1.5;
	letter-spacing: 0.8px;
	display: block;
	padding: 7px 32px;
	&:hover {
		opacity: 0.7;
	}
}
.a-headerBtn {
	width: 100%;
	max-width: 220px;
	margin: auto 0;
	// box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
	&:hover {
		opacity: 0.7;
	}
}
.a-headerBtn_inner {
	display: flex;
	gap: 8px;
	color: #fff;
	justify-content: center;
	align-items: center;
	padding: 10px 16px 10px 8px;
	border-radius: 80px;
	border: 1px solid #fff;
	background: var(--orange, #ffb800);
	box-shadow: 0px 0px 12px 0px rgba(255, 255, 255, 0.75) inset,
		0px 0px 8px 0px rgba(255, 184, 0, 0.3);

	text-align: center;
	font-family: Noto Sans JP;
	font-size: 16px;
	font-weight: 700;
	line-height: 1.2;
	letter-spacing: 0.32px;

	.-marker {
		color: #ff7d61;
		font-size: 15px;
		letter-spacing: 0.3px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 96px;
		background: #fff;
		padding: 7px 14px 7px 16px;
	}
	// .-icon{
	//     position: relative;
	//     width: 16px;
	//     height: 8px;
	//     margin-left: 12px;
	//     background-image: url(../img/common/icon_arrow.svg);
	//     background-repeat: no-repeat;
	//     background-size: contain;
	// }
}

.o-menuToggle {
	position: fixed;
	top: 13px;
	right: 12px;
	width: 32px;
	height: 22px;
	z-index: 1000;
	cursor: pointer;
	span {
		position: relative;
		display: block;
		height: 2px;
		background: var(--global-color-primary);
		margin-top: 10px;
		margin-bottom: 10px;
		&::before,
		&::after {
			content: '';
			position: absolute;
			display: block;
			width: 100%;
			height: 2px;
			background: var(--global-color-primary);
			left: 0;
		}
		&::before {
			top: -10px;
		}
		&::after {
			bottom: -10px;
		}
	}
	@media (min-width: 1200px) {
		display: none;
	}
}

.is-active {
	.o-menuToggle > span {
		// display: none;
		background: transparent;
	}
	.o-menuToggle span:after {
		top: 0;
		transform: rotate(-45deg);
	}
	.o-menuToggle span:before {
		top: 0;
		transform: rotate(45deg);
	}
}
