@charset "UTF-8";
:root {
  --global-color-white: #fff;
  --global-color-black: #1C1F1E;
  --global-color-black-400: #282828;
  --global-color-black-300: #333;
  --global-color-gray-600: #5c5c5c;
  --global-color-gray-500: #666;
  --global-color-gray-400: #A1A1A1;
  --global-color-gray-350: #D9D9D9;
  --global-color-gray-300: #e5e5e5;
  --global-color-gray300: #A1A1A1;
  --global-color-gray200: #F1F1F1;
  --global-color-gray-200: #F1F1F1;
  --global-color-gray-100: #f5f5f5;
  --global-color-red-400: #ED1C24;
  --global-color-red-350: #F3696E;
  --global-color-red-300: #F8A4A7;
  --global-color-red-250: #FCD1D3;
  --global-color-red-200: #FDE7E8;
  --global-color-beige-400: #7A7159;
  --global-color-beige-300: #B9A87B;
  --global-color-beige-200: #C9AD5F;
  --global-color-light-beige: #DFDDD6;
  --orange: #FFB800;
  --global-typography-fontSize-xs: 0.625rem;
  --global-typography-fontSize-sm: 0.75rem;
  --global-typography-fontSize-default: 0.875rem;
  --global-typography-fontSize-lg: 1rem;
  --global-typography-fontSize-xl: 1.125rem;
  --global-typography-fontSize-xxl: 1.25rem;
  --global-typofgraphy-heading-1: 1.5rem;
  --global-typofgraphy-heading-2: 1.25rem;
  --global-typofgraphy-lineHeight-sm: 1.4;
  --global-typofgraphy-lineHeight: 1.5;
  --global-typofgraphy-lineHeight-lg: 1.8;
  --global-typofgraphy-lineHeight-xl: 2;
  --global-color-primary: #0B2945;
  --global-color-primary-contract: var(--global-color-white);
  --global-color-secondary: #0060B8;
  --global-color-text: var(--global-color-black);
  --global-color-text-contract: var(--global-color-white);
  --global-color-border: #ccc;
  --global-color-warn: #ED1C24;
  --global-color-warn-contract: var(--global-color-red-200);
  --global-background-color: var(--global-color-gray-100);
  --global-gradation_gold: linear-gradient(93.28deg, #96855A 1.75%, #947A35 37.67%, #CAAC5D 50.03%, #A68C47 61.39%, #827041 99.83%);
  --global-gradation_gold2: linear-gradient(93.28deg, #AE9553 1.75%, #CFBA83 23.2%, #B99B4B 99.83%);
  --global-spacing-0: 0;
  --global-spacing-1px: 1px;
  --global-spacing-0-5: 2px;
  --global-spacing-1: 4px;
  --global-spacing-1-5: 6px;
  --global-spacing-2: 8px;
  --global-spacing-2-5: 10px;
  --global-spacing-3: 12px;
  --global-spacing-4: 16px;
  --global-spacing-5: 20px;
  --global-spacing-6: 24px;
  --global-spacing-7: 28px;
  --global-spacing-8: 32px;
  --global-spacing-9: 36px;
  --global-spacing-10: 40px;
  --global-spacing-20: 80px;
  --global-ui-disabled-opacity: .5;
  --global-ui-hover-opacity: .5;
  --global-ui-animation-speed: .2s;
  --global-z-index-header: 100;
  --global-z-index-drawer: 59;
  --global-gutter-outer-xs: 15px;
  --global-gutter-outer-md: 60px;
  --global-container-inner-w-md: 1080px;
  --global-container-w: calc(var(--global-gutter-outer-md) * 2 + var(--global-container-inner-w-md));
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

body {
  font-family: "Noto Sans JP", sans-serif;
  color: var(--global-color-black);
}
body.is-active {
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

.font-gradation.-gold {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(93.28deg, #96855a 1.75%, #947a35 37.67%, #caac5d 50.03%, #a68c47 61.39%, #827041 99.83%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.font-gradation.-gold2 {
  background: linear-gradient(93.28deg, #ae9553 1.75%, #cfba83 23.2%, #b99b4b 99.83%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.font-gradation.-gold3 {
  background: linear-gradient(93.92deg, #786432 -12.67%, #9a8449 27.55%, #88784e 106.2%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.-bg_green {
  border-radius: 0px 48px 0px 0px;
  background: var(--bg-green, #f3f9f5);
}
@media (min-width: 769px) {
  .-bg_green {
    border-radius: 0px 120px 0px 0px;
  }
}

.-bg_green-2 {
  border-radius: 0px 48px 0px 0px;
  background: #E8F6EE;
}
@media (min-width: 769px) {
  .-bg_green-2 {
    border-radius: 0px 120px 0px 0px;
  }
}

.site-container {
  position: relative;
}

.site-container__inner {
  width: 100%;
  max-width: 1240px;
  margin: auto;
  margin-top: 120px;
  margin-bottom: 136px;
  padding: 0 20px;
}
@media (min-width: 769px) {
  .site-container__inner {
    padding: 0 80px;
  }
}

.br-pc {
  display: none;
}
@media (min-width: 769px) {
  .br-pc {
    display: block;
  }
}

.br-sp {
  display: block;
}
@media (min-width: 769px) {
  .br-sp {
    display: none;
  }
}

.o-bg_belt {
  display: block;
  height: 128px;
}
.o-bg_belt img {
  height: 100%;
  object-fit: cover;
  object-position: bottom;
}
@media (min-width: 769px) {
  .o-bg_belt {
    height: 256px;
  }
  .o-bg_belt img {
    width: 100%;
  }
}

.m-sct_title {
  color: var(--emeralds-green, #00c992);
  font-family: Albert Sans;
  font-size: 48px;
  font-style: normal;
  font-weight: 100;
  line-height: 1;
  /* 80px */
  text-align: center;
}
@media (min-width: 769px) {
  .m-sct_title {
    font-size: 80px;
  }
}

.m-sct_title_jp {
  color: var(--black, #1c1f1e);
  text-align: center;
  font-family: Noto Sans JP;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  /* 36px */
  margin-top: 8px;
}
@media (min-width: 769px) {
  .m-sct_title_jp {
    font-size: 28px;
  }
}

.sec-description {
  color: var(--black, #1c1f1e);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  /* 32.4px */
  margin-top: 32px;
}
@media (min-width: 769px) {
  .sec-description {
    margin-top: 48px;
  }
}

[data-accordion] {
  --cssui-animation-timing: 0.3s;
  --cssui-animation-rotate: rotate(-180deg);
  --accordion-panel-padding-y: 1em;
}

[data-accordion-item] {
  width: 100%;
}

[data-accordion-item] > input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

[data-accordion-item] > label {
  cursor: pointer;
}

/* Accordion Item's Label Focus State */
[data-accordion-item] > input:focus + label {
  outline: 2px solid #101010;
  outline: 2px solid highlight;
  outline: 2px solid -webkit-focus-ring-color;
}

/* Hide focus ring if focus comes from a pointer device. */
[data-accordion-item] > input:focus:not(:focus-visible) + label {
  outline: none;
}

[data-accordion-item] > label > svg {
  transition: all var(--cssui-animation-timing) ease-out;
}

[data-accordion-panel] {
  max-height: 0;
  transition: padding var(--cssui-animation-timing) ease;
  visibility: hidden;
  padding-block: 0;
}

[data-accordion-item] > input:checked + label > svg {
  transform: rotate(-180deg);
}

[data-accordion-item] > input:checked ~ [data-accordion-panel] {
  max-height: 100vh;
  visibility: visible;
  padding-block: var(--accordion-panel-padding-y);
}

.no-js img.lazyload {
  display: none;
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 0.4s;
}

/**
 * どのような構造で配置されていても、左右画面いっぱいに広がるようにする
 */
/**
  全てのフォームコンポーネントの共通機能
  inputに直接付与すること
 */
/**
  input,textarea,select
 */
/**
GMJのフォームの補助テキストは、基本的に「下揃え」
 */
.a-btn {
  /*
  ボタンの状態向け
   */
  /*
  ボタンのアイコン向け
   */
}
.a-btn.-disabled {
  pointer-events: none;
  opacity: var(--global-ui-disabled-opacity);
}
.a-btn.-withIcon {
  gap: 8px;
}
.a-btn.-iconRight {
  gap: var(--_icon-left-distance, 0);
}

/*
  ボタンのサイズ向け
*/
.a-btn.-size-8 {
  --_space-x: var(--global-spacing-3);
  --_space-y: var(--global-spacing-1-5);
  --_icon-size: 8px;
  --_icon-distance: var(--global-spacing-1);
  padding: var(--_space-y) var(--_space-x);
  min-height: 32px;
}
.a-btn.-size-8 .a-icon {
  font-size: var(--_icon-size);
}
.a-btn.-size-8.-iconFixedRight {
  padding-inline: calc(var(--_icon-distance) * 2 + var(--_icon-size));
}
.a-btn.-size-8.-iconFixedRight .a-icon {
  right: var(--_icon-distance);
}

.a-btn.-size-10 {
  --_icon-size: var(--global-typography-fontSize-lg);
  --_space-x: var(--global-spacing-3);
  padding: 0.5em 1em;
  font-size: var(--global-typography-fontSize-default);
  line-height: var(--global-typofgraphy-lineHeight);
  min-height: 40px;
}
.a-btn.-size-10 .a-icon {
  font-size: var(--global-typography-fontSize-lg);
}
.a-btn.-size-10.-iconFixedRight {
  padding-inline: calc(var(--_space-x) * 2 + var(--_icon-size));
}
.a-btn.-size-10.-iconFixedRight > .a-icon {
  right: var(--_space-x);
}
.a-btn.-size-10.-iconFixedLeft {
  padding-inline: calc(var(--_space-x) * 2 + var(--_icon-size));
}
.a-btn.-size-10.-iconFixedLeft .a-icon {
  left: var(--_space-x);
}

.a-buttonContainer {
  display: block;
}
.a-buttonContainer.-w-250 {
  margin-inline: auto;
  max-width: 250px;
}
.a-buttonContainer.-w-480 {
  margin-inline: auto;
  max-width: 480px;
}

.a-badgePoint {
  --_theme-color: var(--global-color-text);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-inline: 8px;
  border: 1px solid currentColor;
  border-radius: 2px;
  color: var(--_theme-color);
  font-size: var(--global-typography-fontSize-sm);
  line-height: var(--global-typofgraphy-lineHeight);
}
.a-badgePoint.-theme-primary {
  --_theme-color: var(--global-color-red-400);
}

.a-homeSectionHeading {
  display: flex;
  flex-direction: column;
  gap: 0 var(--global-spacing-4);
  align-items: center;
  font-weight: 700;
  font-size: var(--global-typofgraphy-heading-2);
  line-height: var(--global-typofgraphy-lineHeight);
}
@media (min-width: 48em) {
  .a-homeSectionHeading {
    flex-direction: row;
  }
}

.a-homeSectionHeading__labelEn {
  font-family: "Cormorant Garamond", serif;
  letter-spacing: -0.02em;
  font-weight: 400;
  color: var(--global-color-primary);
}
@media (max-width: 47.99em) {
  .a-homeSectionHeading__labelEn {
    order: -1;
  }
}

.m-buttonBookmarkIcon {
  cursor: pointer;
  font-size: 1.5rem;
  color: var(--global-color-primary);
  position: relative;
  width: 40px;
  height: 40px;
}
.m-buttonBookmarkIcon::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 40px;
  height: 40px;
  z-index: 3;
}
.m-buttonBookmarkIcon[data-bookmarked=false] .-saved {
  display: none;
}
.m-buttonBookmarkIcon[data-bookmarked=true] .-save {
  display: none;
}
.m-buttonBookmarkIcon .m-buttonBookmarkIcon__label .a-icon {
  width: 40px;
  height: 40px;
}

.m-buttonBookmark {
  --_color-primary: var(--global-color-primary);
  --_color-primary-contract: var(--global-color-primary-contract);
  cursor: pointer;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: var(--global-spacing-3) var(--global-spacing-4);
  border: 1px solid var(--_color-primary);
  background-color: var(--_color-primary-contract);
  font-size: var(--global-typography-fontSize-default);
  line-height: 1;
  color: var(--_color-primary);
}
.m-buttonBookmark::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}
.m-buttonBookmark:hover {
  opacity: var(--global-ui-hover-opacity);
}
.m-buttonBookmark:not([data-bookmarked]) .-saved, .m-buttonBookmark[data-bookmarked=false] .-saved {
  display: none;
}
.m-buttonBookmark[data-bookmarked=true] {
  background-color: var(--_color-primary);
  border-color: var(--_color-primary);
  color: var(--_color-primary-contract);
}
.m-buttonBookmark[data-bookmarked=true] .-save {
  display: none;
}
.m-buttonBookmark.-theme-fill {
  background-color: var(--_color-primary);
  border-color: var(--_color-primary);
  color: var(--_color-primary-contract);
}

.m-buttonBookmark__label {
  display: flex;
  gap: var(--global-spacing-2-5);
  justify-content: center;
  align-items: center;
}

.m-buttonBookmark__icon {
  font-size: 1em;
  width: 1em;
  height: 1em;
}
.m-buttonBookmark.-mini .m-buttonBookmark__label {
  gap: var(--global-spacing-1);
  flex-direction: column;
  text-align: center;
}
.m-buttonBookmark.-mini .m-buttonBookmark__text {
  font-size: var(--global-typography-fontSize-xs);
}
.m-buttonBookmark.-mini[data-bookmarked=true] {
  background-color: #fff;
  color: var(--global-color-primary);
}

.m-buttonBookmark.-full {
  width: 100%;
}

.o-globalSearchButtons {
  --_size-y: 48px;
  --_size-x: 48px;
  background-color: var(--global-color-white);
}
@media (max-width: 74.99em) {
  .o-globalSearchButtons {
    height: 48px;
  }
}
@media (min-width: 75em) {
  .o-globalSearchButtons {
    width: var(--_size-x);
    height: auto;
  }
}
.o-globalSearchButtons__inner {
  position: relative;
  display: grid;
  gap: 2px;
}
@media (max-width: 74.99em) {
  .o-globalSearchButtons__inner {
    grid-template-columns: repeat(2, 1fr);
    height: 100%;
  }
}
@media (min-width: 75em) {
  .o-globalSearchButtons__inner {
    grid-template-columns: var(--_size-x);
  }
}

.o-globalSearchButtons__button {
  position: relative;
}
@media (min-width: 75em) {
  .o-globalSearchButtons__button * {
    writing-mode: vertical-rl;
  }
}
.o-globalSearchButtons__button > a, .o-globalSearchButtons__button > button,
.o-globalSearchButtons__button .a-btn {
  max-width: 100%;
  max-height: 100%;
  display: flex;
  width: 100%;
}
@media (max-width: 74.99em) {
  .o-globalSearchButtons__button > a, .o-globalSearchButtons__button > button,
.o-globalSearchButtons__button .a-btn {
    padding: 0 1em;
    height: var(--_size-y);
    font-size: 0.75rem;
  }
  .o-globalSearchButtons__button > a .a-icon, .o-globalSearchButtons__button > button .a-icon,
.o-globalSearchButtons__button .a-btn .a-icon {
    display: none;
  }
}
@media (min-width: 75em) {
  .o-globalSearchButtons__button > a, .o-globalSearchButtons__button > button,
.o-globalSearchButtons__button .a-btn {
    padding: 1em 0;
    width: var(--_size-x);
    font-size: 0.9375rem;
  }
}
@media (min-width: 75em) {
  .o-globalSearchButtons__button.-use-search .for-narrow {
    display: none;
  }
}
@media (max-width: 74.99em) {
  .o-globalSearchButtons__button.-use-search .for-wide {
    display: none;
  }
}
@media (min-width: 75em) {
  .o-globalSearchButtons__button.-toForm .o-globalSearchButtons__buttonToForm.-narrow {
    display: none;
  }
}
@media (max-width: 74.99em) {
  .o-globalSearchButtons__button.-toForm .o-globalSearchButtons__buttonToForm.-wide {
    display: none;
  }
}
@media (min-width: 75em) {
  .o-globalSearchButtons__button.-toForm .-br-narrow {
    display: none;
  }
}
.o-globalSearchButtons__button.-use-myPage-history .a-btn .a-icon {
  display: none;
}

.o-globalSearchButtons__SearchModal {
  display: none;
}

.m-jobInfoCard {
  --_body-padding: 20px;
  overflow: hidden;
  font-weight: 500;
  background-color: var(--global-color-white);
}
@media (hover: hover) {
  .m-jobInfoCard:not(.-end):hover {
    opacity: var(--global-ui-disabled-opacity);
  }
}

.m-jobInfoCard__inner {
  position: relative;
}

.m-jobInfoCard__body {
  padding: var(--_body-padding);
  display: grid;
  grid-gap: 0 var(--global-spacing-2);
  gap: 0 var(--global-spacing-2);
  grid-template: "name name" auto "data save" auto/1fr 40px;
}

.m-jobInfoCard__footer {
  overflow: hidden;
  border-top: 1px solid var(--global-color-gray-300);
  padding: 8px var(--_body-padding);
  height: auto !important;
}

.m-jobInfoCard__tags {
  position: relative;
  overflow: hidden;
  max-height: 100%;
  padding: var(--_body-padding);
  height: 130px;
}
.m-jobInfoCard__tags .m-tags.-more::before {
  bottom: 29.6666666667%;
}

.m-jobInfoCard__applied {
  word-break: break-all;
  font-size: var(--global-form-typofgraphy-fontSize-sm);
  line-height: var(--global-form-typofgraphy-lineHeight-lg);
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 0.5em;
}

.m-jobInfoCard__link {
  display: block;
}
.m-jobInfoCard__link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-jobInfoCard__thumb {
  position: relative;
  display: block;
}

.m-jobInfoCard__img {
  aspect-ratio: 254/143;
  object-fit: cover;
  display: block;
  width: 100%;
}

.m-jobInfoCard__description {
  display: block;
  background-color: var(--global-color-black);
  font-size: 1rem;
  line-height: 1.4;
  color: var(--global-color-white);
  padding: 6px 8px 6px;
}
.m-jobInfoCard__description .text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.m-jobInfoCard__company {
  grid-area: name;
  font-size: var(--global-typography-fontSize-lg);
  line-height: var(--global-typofgraphy-lineHeight-sm);
}

.m-jobInfoCard__data {
  grid-area: data;
  margin-top: var(--global-spacing-4);
  font-size: var(--global-typography-fontSize-sm);
  line-height: var(--global-typofgraphy-lineHeight);
  color: var(--global-color-gray-400);
}
.m-jobInfoCard__data li {
  position: relative;
  padding-left: 21px;
}
.m-jobInfoCard__data li .a-icon {
  position: absolute;
  top: 4px;
  left: 0;
}
.m-jobInfoCard__data li + li {
  margin-top: var(--global-spacing-1);
}

.m-jobInfoCard__save {
  position: relative;
  grid-area: save;
  align-self: end;
  z-index: 2;
}
.m-jobInfoCard__save .m-buttonBookmarkIcon {
  z-index: 2;
  position: absolute;
  top: auto;
  right: 0;
  bottom: 0;
  left: auto;
}

.m-jobInfoCard__end {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.m-jobInfoCard__endText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  font-size: 1.25rem;
  letter-spacing: 0.2em;
  text-align: center;
  color: var(--global-color-white);
}

.m-jobInfoCard.-bordered .m-jobInfoCard__body {
  border: 1px solid var(--global-color-gray-200);
  border-top-width: 0;
}

.m-jobInfoCard.-inventory {
  background-color: var(--global-color-gray-200);
}
.m-jobInfoCard.-inventory .m-jobInfoCard__inner {
  display: flex;
  flex-direction: column;
}
@media (min-width: 48em) {
  .m-jobInfoCard.-inventory .m-jobInfoCard__inner {
    flex-direction: row;
  }
}
.m-jobInfoCard.-inventory .m-jobInfoCard__body {
  display: block;
  padding: 0 14px 24px;
}
@media (min-width: 48em) {
  .m-jobInfoCard.-inventory .m-jobInfoCard__body {
    flex: 1;
    padding: 40px 24px 40px 0;
  }
}
.m-jobInfoCard.-inventory .m-jobInfoCard__body .row {
  display: flex;
  flex-direction: column;
}
@media (min-width: 48em) {
  .m-jobInfoCard.-inventory .m-jobInfoCard__body .row {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }
}
.m-jobInfoCard.-inventory .m-jobInfoCard__body .row .link {
  text-align: center;
  margin: 20px auto 0;
  width: 178px;
}
@media (min-width: 48em) {
  .m-jobInfoCard.-inventory .m-jobInfoCard__body .row .link {
    text-align: left;
    margin-top: 0;
  }
}
.m-jobInfoCard.-inventory .m-jobInfoCard__body .row .link .a-btn {
  font-size: 1rem;
  min-height: 42px;
  padding-right: 40px;
  padding-left: 40px;
  font-weight: 700;
}
.m-jobInfoCard.-inventory .m-jobInfoCard__company {
  margin-top: 10px;
  font-size: 1.125rem;
}
.m-jobInfoCard.-inventory .m-jobInfoCard__data {
  margin-top: 8px;
  font-size: 0.875rem;
}
@media (min-width: 48em) {
  .m-jobInfoCard.-inventory .m-jobInfoCard__data {
    flex: 1;
  }
}
.m-jobInfoCard.-inventory .m-jobInfoCard__thumb {
  position: relative;
  width: 100%;
  padding: 14px;
}
.m-jobInfoCard.-inventory .m-jobInfoCard__thumb img {
  width: 100%;
}
@media (min-width: 48em) {
  .m-jobInfoCard.-inventory .m-jobInfoCard__thumb {
    width: 272px;
    padding: 24px;
  }
  .m-jobInfoCard.-inventory .m-jobInfoCard__thumb img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 224px;
    height: 190px;
    object-fit: cover;
  }
}
.m-jobInfoCard.-inventory .m-jobInfoCard__description {
  font-size: 1.125rem;
  padding: 0;
  background-color: transparent;
  font-weight: bold;
  color: inherit;
}
.m-jobInfoCard.-inventory .m-jobInfoCard__description .text {
  background-color: inherit;
  color: inherit;
}
.m-jobInfoCard.-inventory .m-jobInfoCard__pref,
.m-jobInfoCard.-inventory .m-jobInfoCard__income {
  display: block;
  margin-top: 3px;
}

.m-jobInfoCard.-sidebar .m-jobInfoCard__body {
  display: block;
  padding: 10px 10px 12px;
}
.m-jobInfoCard.-sidebar .m-jobInfoCard__img {
  width: 100%;
}
.m-jobInfoCard.-sidebar .m-jobInfoCard__data {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
.m-jobInfoCard.-sidebar .m-jobInfoCard__data > * {
  display: inline-flex;
  min-width: 0;
  max-width: 230px;
}
.m-jobInfoCard.-sidebar .m-jobInfoCard__data .m-jobInfoCard__industry,
.m-jobInfoCard.-sidebar .m-jobInfoCard__data .m-jobInfoCard__job {
  width: 100%;
}
.m-jobInfoCard.-sidebar .m-jobInfoCard__data .m-jobInfoCard__pref {
  width: 26.0869565217%;
}
.m-jobInfoCard.-sidebar .m-jobInfoCard__data .m-jobInfoCard__income {
  width: 65.2173913043%;
}
.m-jobInfoCard.-sidebar .m-jobInfoCard__data .label {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.m-jobInfoCard.-sidejob .m-jobInfoCard__body {
  display: grid;
  grid-gap: 0 0;
  gap: 0 0;
  grid-template: "name name" auto "data save" auto/1fr 0;
}

.m-jobInfoCard.-simple {
  position: relative;
}
@media (max-width: 47.99em) {
  .m-jobInfoCard.-simple .m-jobInfoCard__footer, .m-jobInfoCard.-simple .m-jobInfoCard__new, .m-jobInfoCard.-simple .m-jobInfoCard__thumb {
    display: none;
  }
}
@media (max-width: 47.99em) {
  .m-jobInfoCard.-simple .m-jobInfoCard__inner {
    position: static;
  }
}
@media (max-width: 47.99em) {
  .m-jobInfoCard.-simple .m-jobInfoCard__thumb {
    position: static;
  }
}
@media (max-width: 47.99em) {
  .m-jobInfoCard.-simple .m-jobInfoCard__body {
    padding-block: var(--global-spacing-4);
  }
}
.m-jobInfoCard.-simple .m-jobInfoCard__body .m-jobInfoCard__end {
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 10;
}
@media (min-width: 48em) {
  .m-jobInfoCard.-simple .m-jobInfoCard__body .m-jobInfoCard__end {
    display: none;
  }
}
.m-jobInfoCard.-simple .m-jobInfoCard__body .m-jobInfoCard__endText {
  position: absolute;
  top: -86px;
  right: -86px;
  left: auto;
  bottom: auto;
  transform: rotate(45deg);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 4px;
  width: 162.8127759115px;
  height: 162.8127759115px;
  background-color: var(--global-color-black);
  font-size: 1rem;
  line-height: 1;
  color: var(--global-color-white);
}
@media (max-width: 47.99em) {
  .m-jobInfoCard.-simple .m-jobInfoCard__company {
    font-size: 1.125rem;
    line-height: 1.3;
  }
}
@media (max-width: 47.99em) {
  .m-jobInfoCard.-simple .m-jobInfoCard__data {
    margin-top: 12.375px;
    display: flex;
    flex-wrap: wrap;
    font-size: 0.875rem;
    line-height: 1.375;
  }
  .m-jobInfoCard.-simple .m-jobInfoCard__data li + li {
    margin-top: 6.75px;
  }
}
@media (max-width: 47.99em) {
  .m-jobInfoCard.-simple .m-jobInfoCard__job {
    flex: 1 1 100%;
    min-width: 100%;
  }
}
@media (max-width: 47.99em) {
  .m-jobInfoCard.-simple .m-jobInfoCard__pref {
    margin-right: var(--global-spacing-2);
    flex-shrink: 1;
  }
}
@media (max-width: 47.99em) {
  .m-jobInfoCard.-simple .m-jobInfoCard__income {
    flex-grow: 1;
  }
}
.m-jobInfoDetail__description {
  margin-block: var(--global-spacing-5);
  font-size: 1.1875rem;
  line-height: 1.5789473684;
}
@media (min-width: 48em) {
  .m-jobInfoDetail__description {
    margin-block: var(--global-spacing-7);
    font-size: 1.5rem;
    line-height: 1.6666666667;
  }
}
.m-jobInfoDetail__description .a-icon {
  position: relative;
  top: -0.1em;
  font-size: 1.25rem;
  color: var(--global-color-primary);
}
@media (min-width: 48em) {
  .m-jobInfoDetail__description .a-icon {
    display: none;
  }
}
@media (min-width: 48em) {
  .m-jobInfoDetail__description .a-icon {
    font-size: 0.9375rem;
  }
}

.m-jobInfoDetail__company {
  display: flex;
  align-items: flex-start;
}
.m-jobInfoDetail__company .heading {
  font-size: 1.125rem;
  line-height: 1.3333333333;
}
@media (min-width: 48em) {
  .m-jobInfoDetail__company .heading {
    font-size: 1.25rem;
    line-height: 1.2;
  }
}

.m-jobInfoDetail__badges {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 10px;
}
.m-jobInfoDetail__badges .a-badge {
  width: 45px;
}
@media (min-width: 48em) {
  .m-jobInfoDetail__badges .a-badge {
    width: 50px;
  }
}
.m-jobInfoDetail__badges .a-badge + .a-badge {
  margin-left: 10px;
}

.m-jobInfoDetail__data {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  font-size: 0.9375rem;
}
@media (max-width: 47.99em) {
  .m-jobInfoDetail__data {
    margin-bottom: -15px;
  }
}
@media (min-width: 48em) {
  .m-jobInfoDetail__data {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
  }
}
.m-jobInfoDetail__data li {
  margin-right: 10px;
  margin-left: 10px;
  position: relative;
  padding-left: 1.25rem;
  line-height: 1.2;
}
@media (min-width: 48em) {
  .m-jobInfoDetail__data li {
    padding-left: 1.25rem;
  }
}
@media (max-width: 47.99em) {
  .m-jobInfoDetail__data li:not(:last-child) {
    margin-bottom: 15px;
  }
}
.m-jobInfoDetail__data .a-icon {
  position: absolute;
  top: 0;
  left: 0;
  margin-right: 1em;
  font-size: 0.9375rem;
  vertical-align: bottom;
  color: var(--global-color-primary);
}
@media (min-width: 48em) {
  .m-jobInfoDetail__data .a-icon {
    margin-right: 5px;
  }
}

.m-jobInfoDetail__pref,
.m-jobInfoDetail__income {
  flex: 0 1 auto;
}

@media (max-width: 37.49em) {
  .m-jobInfoDetail__job {
    margin-bottom: 15px;
    flex: 1 1 100%;
    max-width: 100%;
  }
}

.m-jobInfoDetail__pref {
  margin-right: 20px;
}
@media (min-width: 37.5em) {
  .m-jobInfoDetail__pref {
    margin-right: 40px;
  }
}

.m-jobInfoDetail.-noThumb .m-jobInfoDetail__header {
  display: grid;
  grid-gap: 0 1.25rem;
  gap: 0 1.25rem;
  grid-template: "jobInfoDetailNoThumbCompany" auto "jobInfoDetailNoThumbData" auto/1fr;
}
.m-jobInfoDetail.-noThumb .m-jobInfoDetail__company {
  grid-area: jobInfoDetailNoThumbCompany;
}
.m-jobInfoDetail.-noThumb .m-jobInfoDetail__data {
  grid-area: jobInfoDetailNoThumbData;
  margin-top: var(--global-spacing-3);
}
.m-jobInfoDetail.-noThumb .m-jobInfoDetail__tags {
  margin-top: 20px;
}
@media (min-width: 48em) {
  .m-jobInfoDetail.-noThumb .m-jobInfoDetail__tags {
    margin-top: 30px;
  }
}
.m-jobInfoDetail.-noThumb .m-jobInfoDetail__body {
  margin-top: 25px;
  border-top: 0.0625rem solid #000;
}
@media (min-width: 48em) {
  .m-jobInfoDetail.-noThumb .m-jobInfoDetail__body {
    margin-top: 30px;
  }
}
.m-jobInfoDetail.-large .m-jobInfoDetail__header {
  display: grid;
  grid-gap: 0 0;
  gap: 0 0;
  grid-template: "jobInfoDetailLargeLogo" auto "jobInfoDetailLargeCompany" auto "jobInfoDetailLargeData" auto "jobInfoDetailLargeDescription" auto "jobInfoDetailLargeTags" auto/1fr;
}
@media (min-width: 48em) {
  .m-jobInfoDetail.-large .m-jobInfoDetail__header {
    grid-gap: 0 30px;
    gap: 0 30px;
    grid-template: "jobInfoDetailLargeLogo jobInfoDetailLargeCompany" auto "jobInfoDetailLargeLogo jobInfoDetailLargeData" auto "jobInfoDetailLargeLogo jobInfoDetailLargeDescription" auto "jobInfoDetailLargeLogo jobInfoDetailLargeTags" auto/200px 1fr;
  }
}
.m-jobInfoDetail.-large .m-jobInfoDetail__tags {
  grid-area: jobInfoDetailLargeTags;
}
.m-jobInfoDetail.-large .m-jobInfoDetail__data {
  grid-area: jobInfoDetailLargeData;
}
.m-jobInfoDetail.-large .m-jobInfoDetail__company {
  grid-area: jobInfoDetailLargeCompany;
  margin-bottom: var(--global-spacing-3);
}
@media (max-width: 47.99em) {
  .m-jobInfoDetail.-large .m-jobInfoDetail__company {
    margin-top: var(--global-spacing-7);
  }
}
.m-jobInfoDetail.-large .m-jobInfoDetail__description {
  grid-area: jobInfoDetailLargeDescription;
  margin-block: var(--global-spacing-5);
  font-size: 0.875rem;
  line-height: 1.7142857143;
}
@media (min-width: 48em) {
  .m-jobInfoDetail.-large .m-jobInfoDetail__description {
    margin-block: var(--global-spacing-7);
    font-size: 1.0625rem;
    line-height: 1.2941176471;
    font-weight: 700;
  }
}
.m-jobInfoDetail.-large .m-jobInfoDetail__logo {
  grid-area: jobInfoDetailLargeLogo;
  height: auto;
  text-align: center;
}
@media (min-width: 48em) {
  .m-jobInfoDetail.-large .m-jobInfoDetail__logo {
    width: 200px;
  }
}

.m-jobInfoDetail__img {
  object-fit: cover;
  aspect-ratio: 25/14;
}

.m-linkButtonToPostAJob {
  font-weight: 700;
}

.m-linkButtonUserAccount {
  border-width: 2px;
}

.m-linkButtonUserAccount__label {
  font-weight: 700;
}

.m-pageButton {
  --_bg-color: var(--global-color-white);
  --_color: var(--global-color-black);
  --_bd-color: currentColor;
  --_size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--_size);
  height: var(--_size);
  font-size: var(--global-typography-fontSize-default);
  line-height: 1;
  border: 1px solid var(--_bd-color);
  background-color: var(--_bg-color);
  color: var(--_color);
}
.m-pageButton.-current {
  --_bg-color: var(--global-color-primary);
  --_color: var(--global-color-white);
  --_bd-color: var(--global-color-primary);
}
.o-headerMenus {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--global-spacing-1);
}

.o-headerMenus__row {
  display: grid;
  gap: var(--global-spacing-1);
}
.o-headerMenus__row.-use-notLoggedIn {
  grid-template-columns: 204px 1fr 1fr;
}
.o-headerMenus__row.-use-loggedIn {
  grid-template-columns: repeat(3, 1fr);
}
.o-heroCarousel .swiper-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}
.o-heroCarousel .swiper-pagination-bullet {
  background-color: var(--global-color-gray-500);
  border-radius: 50%;
  width: 0.5rem;
  height: 0.5rem;
}
.o-heroCarousel .swiper-pagination-bullet-active {
  background-color: var(--global-color-primary);
}
.o-heroCarousel__item {
  max-width: 704px;
}
.o-heroCarousel__item:hover {
  opacity: var(--global-ui-hover-opacity);
  cursor: pointer;
}
.o-heroCarousel__item picture, .o-heroCarousel__item img {
  aspect-ratio: 88/85;
  display: block;
  width: 100%;
  max-height: 680px;
  height: auto;
}
@media (min-width: 44em) {
  .o-heroCarousel__item picture, .o-heroCarousel__item img {
    aspect-ratio: 25/14;
    max-height: 394px;
    height: 394px;
  }
}

.o-heroCarousel__pagination {
  margin-top: 20px;
}

.o-jobDetailAction.-float {
  --_button-garter: var(--global-spacing-1);
}
.o-jobDetailAction.-float .o-jobDetailAction__jmc {
  margin-top: 20px;
}
.o-jobDetailAction.-float .o-jobDetailAction__buttons {
  display: flex;
  justify-content: space-between;
  gap: var(--_button-garter);
}
.o-jobDetailAction.-float .o-jobDetailAction__button {
  --_button-h: 40px;
  flex: 0 0 max-content;
  min-width: 0;
}
.o-jobDetailAction.-float .o-jobDetailAction__button .a-btn,
.o-jobDetailAction.-float .o-jobDetailAction__button .m-buttonBookmark {
  padding: 0 var(--_button-garter);
  width: 100%;
  height: var(--_button-h);
}
.o-jobDetailAction.-float .o-jobDetailAction__button.-ask {
  flex: 1 1 auto;
}
.o-jobDetailAction.-float .o-jobDetailAction__button.-ask .a-btn {
  font-size: 0.875rem;
}
.o-jobDetailAction.-float .o-jobDetailAction__button.-bookmark {
  max-width: 96px;
}
.o-jobDetailAction.-float .o-jobDetailAction__button.-bookmarkList {
  max-width: 96px;
}

.o-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.o-pagination__list {
  max-width: 330px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--global-spacing-3);
}
@media (max-width: 29.99em) {
  .o-pagination__list {
    display: grid;
    grid-template: "paginationPrev paginationNext" auto "paginationPages paginationPages" auto/1fr 1fr;
  }
}

.o-pagination__pages {
  order: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--global-spacing-3);
}
@media (max-width: 29.99em) {
  .o-pagination__pages {
    justify-content: flex-start;
    grid-area: paginationPages;
  }
}

.o-pagination__item {
  flex: 0 0 auto;
  max-width: max-content;
}
.o-pagination__item.-prev {
  margin-right: auto;
}
@media (max-width: 29.99em) {
  .o-pagination__item.-prev {
    grid-area: paginationPrev;
    margin-right: auto;
    padding-right: 0;
  }
}
.o-pagination__item.-next {
  order: 4;
}
@media (max-width: 29.99em) {
  .o-pagination__item.-next {
    grid-area: paginationNext;
    margin-left: auto;
    padding-left: 0;
  }
}

.o-simpleSearchForm {
  --_cross-icon-size: 14px;
  --_form-field-gap: var(--global-spacing-2);
}
@media (min-width: 60em) {
  .o-simpleSearchForm {
    --_form-field-gap: var(--global-spacing-4);
  }
}
.o-simpleSearchForm .a-formSelect {
  width: 100%;
}
.o-simpleSearchForm__location, .o-simpleSearchForm__industry,
.o-simpleSearchForm__areas {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--_form-field-gap);
  align-items: center;
}
@media (min-width: 60em) {
  .o-simpleSearchForm__location, .o-simpleSearchForm__industry,
.o-simpleSearchForm__areas {
    flex-direction: row;
  }
}
.o-simpleSearchForm__location:hover .o-simpleSearchForm__hoverShowItem, .o-simpleSearchForm__industry:hover .o-simpleSearchForm__hoverShowItem,
.o-simpleSearchForm__areas:hover .o-simpleSearchForm__hoverShowItem {
  opacity: 1;
}

.o-simpleSearchForm__hoverShowItem {
  position: absolute;
  bottom: calc(100% - 0.25rem);
  left: calc(100% - 2.5rem);
  width: 20rem;
  opacity: 0;
  transition: opacity 0.3s;
}
@media (max-width: 47.99em) {
  .o-simpleSearchForm__hoverShowItem {
    display: none;
  }
}

@media (max-width: 47.99em) {
  .o-simpleSearchForm__areas {
    display: none;
  }
}

.o-simpleSearchForm__location {
  margin-right: 0;
}
@media (max-width: 47.99em) {
  .o-simpleSearchForm__location {
    flex: 0 0 59%;
  }
}
.o-simpleSearchForm__location::before, .o-simpleSearchForm__location::after {
  display: none;
}
.o-simpleSearchForm__location .o-simpleSearchForm__hoverShowItem {
  left: calc(100% - 6.25rem);
}
@media (max-width: 74.99em) {
  .o-simpleSearchForm__location .o-simpleSearchForm__hoverShowItem {
    left: 1.25rem;
  }
}

.o-simpleSearchForm__inner {
  display: flex;
  flex-direction: column;
  gap: var(--global-spacing-4);
}
@media (min-width: 60em) {
  .o-simpleSearchForm__inner {
    gap: var(--_form-field-gap);
  }
}
@media (min-width: 75em) {
  .o-simpleSearchForm__inner {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.o-simpleSearchForm__inputs {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--_form-field-gap);
}
@media (min-width: 60em) {
  .o-simpleSearchForm__inputs {
    flex: 1 1 auto;
    min-width: 0;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
  }
}
.o-simpleSearchForm__inputs .icon-divider {
  position: relative;
  width: var(--_cross-icon-size);
  height: var(--_cross-icon-size);
}
.o-simpleSearchForm__inputs .icon-divider::before, .o-simpleSearchForm__inputs .icon-divider::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: var(--_cross-icon-size);
  height: 1px;
  background-color: var(--global-color-gray-400);
}
.o-simpleSearchForm__inputs .icon-divider::before {
  transform: translateY(-50%) rotate(45deg);
}
.o-simpleSearchForm__inputs .icon-divider::after {
  transform: translateY(-50%) rotate(-45deg);
}

@media (min-width: 75em) {
  .o-simpleSearchForm__submit {
    max-width: 100%;
    flex: 1 1 auto;
    min-width: 0;
  }
}
.o-simpleSearchForm__button {
  width: 100%;
}

/**
 * 挙動について
 * xlまでは、ヘッダーはstickyで、サイドバーは1カラム落ちしている
 */
.site-container {
  position: relative;
}
@media (min-width: 75em) {
  .site-container {
    overflow-x: hidden;
  }
}
.site-container .o-header {
  position: sticky;
  top: 0;
  z-index: 1000;
}
@media (min-width: 75em) {
  .site-container .o-header {
    position: relative;
  }
}

.site-main {
  overflow-x: hidden;
}
@media (min-width: 75em) {
  .site-main {
    overflow-x: visible;
  }
}

.t-hasSidebar {
  --template-layout-main-space: 50px;
  --template-layout-gutter-both-ends: var(--global-gutter-outer-xs);
  --template-layout-content-maxw: 800px;
  --template-layout-sidebar-maxw: 310px;
}
@media (min-width: 75em) {
  .t-hasSidebar {
    --template-layout-gutter-both-ends: var(--global-gutter-outer-md);
  }
}
.t-hasSidebar .site-main {
  margin-inline: auto;
  padding-inline: var(--template-layout-gutter-both-ends);
  max-width: 1280px;
}
.t-hasSidebar .site-main__inner {
  margin: var(--template-layout-main-space) auto;
  max-width: calc(var(--template-layout-main-space) + var(--template-layout-content-maxw) + var(--template-layout-sidebar-maxw));
}
@media (min-width: 75em) {
  .t-hasSidebar .site-main__inner {
    display: flex;
    margin-inline: calc(var(--template-layout-main-space) * -1);
    justify-content: space-between;
  }
}
@media (min-width: 80em) {
  .t-hasSidebar .site-main__inner {
    margin-inline: auto;
  }
}
.t-hasSidebar .site-main-content {
  max-width: var(--template-layout-content-maxw);
}
@media (max-width: 74.99em) {
  .t-hasSidebar .site-main-content {
    margin-inline: auto;
  }
}
@media (min-width: 75em) {
  .t-hasSidebar .site-main-content {
    flex: 1 1 auto;
    min-width: 0;
  }
}
.t-hasSidebar .site-sidebar {
  max-width: var(--template-layout-content-maxw);
}
@media (max-width: 74.99em) {
  .t-hasSidebar .site-sidebar {
    margin-inline: auto;
  }
}
@media (min-width: 75em) {
  .t-hasSidebar .site-sidebar {
    position: relative;
    flex: 0 0 310px;
    min-width: 0;
    max-width: var(--template-layout-sidebar-maxw);
  }
}
.t-hasSidebar .site-main-footer__content {
  margin-left: 0;
}
.t-hasSidebar .o-footer {
  margin-top: var(--template-layout-main-space);
}

.p-home .p-home-simpleSearch {
  margin-top: 48px;
}

.p-home-heroimage {
  position: relative;
  left: 50%;
  right: 50%;
  margin-right: -50vw;
  margin-left: -50vw;
  width: 100vw;
  z-index: 1;
}

.p-home-simpleSearch {
  margin-top: 48px;
  padding: 48px 40px;
  background-color: var(--global-color-white);
  border: 1px solid var(--global-color-primary);
}
.p-home-simpleSearch__title {
  margin-inline: auto;
  margin-bottom: 16px;
  max-width: 1156px;
  font-weight: 700;
}

.o-header {
  --_narrow-p-r: 64px;
  background-color: var(--global-color-white);
  color: var(--global-color-black);
  padding: 12px 24px 12px 12px;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (min-width: 1200px) {
  .o-header {
    padding: 12px 24px 12px 40px;
    height: 74px;
  }
}
.o-header .o-headerNavwrap {
  display: none;
  flex: auto;
  justify-content: flex-end;
  align-items: center;
  margin-left: 24px;
}
@media (min-width: 1200px) {
  .o-header .o-headerNavwrap {
    display: flex;
  }
}
.o-header .a-headerLogo {
  display: block;
  width: 208px;
}
@media (min-width: 1200px) {
  .o-header .a-headerLogo {
    width: 140px;
  }
}

.o-headerNav__list {
  display: flex;
  margin-right: 16px;
}

.o-headerNav__link {
  color: var(--black, #1c1f1e);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.8px;
  display: block;
  padding: 7px 32px;
}
.o-headerNav__link:hover {
  opacity: 0.7;
}

.a-headerBtn {
  width: 100%;
  max-width: 220px;
  margin: auto 0;
}
.a-headerBtn:hover {
  opacity: 0.7;
}

.a-headerBtn_inner {
  display: flex;
  gap: 8px;
  color: #fff;
  justify-content: center;
  align-items: center;
  padding: 10px 16px 10px 8px;
  border-radius: 80px;
  border: 1px solid #fff;
  background: var(--orange, #ffb800);
  box-shadow: 0px 0px 12px 0px rgba(255, 255, 255, 0.75) inset, 0px 0px 8px 0px rgba(255, 184, 0, 0.3);
  text-align: center;
  font-family: Noto Sans JP;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.32px;
}
.a-headerBtn_inner .-marker {
  color: #ff7d61;
  font-size: 15px;
  letter-spacing: 0.3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 96px;
  background: #fff;
  padding: 7px 14px 7px 16px;
}

.o-menuToggle {
  position: fixed;
  top: 13px;
  right: 12px;
  width: 32px;
  height: 22px;
  z-index: 1000;
  cursor: pointer;
}
.o-menuToggle span {
  position: relative;
  display: block;
  height: 2px;
  background: var(--global-color-primary);
  margin-top: 10px;
  margin-bottom: 10px;
}
.o-menuToggle span::before, .o-menuToggle span::after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  background: var(--global-color-primary);
  left: 0;
}
.o-menuToggle span::before {
  top: -10px;
}
.o-menuToggle span::after {
  bottom: -10px;
}
@media (min-width: 1200px) {
  .o-menuToggle {
    display: none;
  }
}

.is-active .o-menuToggle > span {
  background: transparent;
}
.is-active .o-menuToggle span:after {
  top: 0;
  transform: rotate(-45deg);
}
.is-active .o-menuToggle span:before {
  top: 0;
  transform: rotate(45deg);
}

footer {
  background: var(--linear, linear-gradient(135deg, #00C992 0%, #0A7B5C 100%));
}

.o-footer__inner {
  display: block;
  justify-content: space-between;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 180%;
  padding: 40px;
}
@media (min-width: 769px) {
  .o-footer__inner {
    display: flex;
  }
}

.o-footer_logo {
  display: block;
}
@media (min-width: 769px) {
  .o-footer_logo {
    display: flex;
  }
}
.o-footer_logo .a-Logo {
  display: block;
  width: 120px;
  padding-right: 18px;
}
@media (min-width: 769px) {
  .o-footer_logo .a-Logo {
    width: 200px;
  }
}
.o-footer_logo .text_wrap {
  margin-top: 16px;
}
.o-footer_logo .text_wrap p + p {
  margin-top: 8px;
}
@media (min-width: 769px) {
  .o-footer_logo .text_wrap {
    margin-top: 0;
  }
}

.o-footer_nav_wrap {
  margin-top: 16px;
}
.o-footer_nav_wrap ul {
  display: flex;
  justify-content: flex-start;
}
.o-footer_nav_wrap ul li + li {
  margin-left: 40px;
}
.o-footer_nav_wrap ul a {
  text-decoration: underline;
}
.o-footer_nav_wrap ul a:hover {
  text-decoration: none;
}
.o-footer_nav_wrap .copy {
  margin-top: 18px;
  text-align: left;
}
@media (min-width: 769px) {
  .o-footer_nav_wrap {
    margin-top: 0;
  }
  .o-footer_nav_wrap ul {
    justify-content: flex-end;
  }
  .o-footer_nav_wrap .copy {
    text-align: right;
  }
}

.sticky_area {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding-top: 8px;
  padding-bottom: 8px;
  z-index: 9990;
  transition: 0.4s;
}
.sticky_area .a-cv_btn_wrap {
  max-width: 327px;
  margin: auto;
}
@media (min-width: 769px) {
  .sticky_area {
    display: none;
  }
}
.js-intersection_flag--active .sticky_area {
  opacity: 0;
  pointer-events: none;
}

.o-menuNavwrap {
  display: none;
  overflow-x: hidden;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  background: #fff;
  height: 100vh;
  height: 100lvh;
  padding: 104px 48px 0;
  width: 100vw;
}
.is-active .o-menuNavwrap {
  display: block;
}
@media (min-width: 1200px) {
  .o-menuNavwrap {
    display: none !important;
  }
}

.o-menuNav__item + .o-menuNav__item {
  margin-top: 32px;
}

.o-menuNav__link {
  position: relative;
  padding-left: 24px;
  color: var(--black, #1C1F1E);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Noto Sans JP;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  /* 24px */
  letter-spacing: 0.8px;
}
.o-menuNav__link::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: block;
  width: 12px;
  height: 12px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../img/top/icon_arrow2.svg);
}