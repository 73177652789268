@use 'css/abstracts' as *;
$o-header__logo_w: 200;
$o-header__logo_w--full: 400;
$o-header__head_pd-vertical: 12;
$o-header__head_pdb-vertical: $o-header__head_pd-vertical + 6;
$o-header__head_pd-vertical--full: 20;
$o-header__head_pdb-vertical--full: 0;

footer {
  background: var(--linear, linear-gradient(135deg, #00C992 0%, #0A7B5C 100%));
}
.o-footer__inner {
	display: block;
	justify-content: space-between;
	color: #fff;
	// font-family: YuGothic, 'Yu Gothic', sans-serif;
	font-weight: 500;
	font-size: 14px;
	line-height: 180%;
	padding: 40px;
	@media (min-width: 769px) {
		display: flex;
	}
}
.o-footer_logo {
	display: block;
	@media (min-width: 769px) {
        display: flex;
	}
	.a-Logo {
        display: block;
		width: 120px;
		padding-right: 18px;
		@media (min-width: 769px) {
			width: 200px;
		}
	}
	.text_wrap {
        margin-top: 16px;
		p + p {
            margin-top: 8px;
		}
        @media (min-width: 769px) {
            margin-top: 0;
        }
	}
}
.o-footer_nav_wrap {
	margin-top: 16px;

	ul {
		display: flex;
		justify-content: flex-start;
		li + li {
			margin-left: 40px;
		}
		a {
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}
	.copy {
		margin-top: 18px;
		text-align: left;
	}
	@media (min-width: 769px) {
		margin-top: 0;
		ul {
			justify-content: flex-end;
		}
		.copy {
			text-align: right;
		}
	}
}
.sticky_area {
	position: fixed;
  // position: sticky;
	bottom: 0;
	left: 0;
  right: 0;
  margin: auto;
	padding-top: 8px;
	padding-bottom: 8px;
	z-index: 9990;
  transition: .4s;
	.a-cv_btn_wrap {
		max-width: 327px;
		margin: auto;
	}
	@media (min-width: 769px) {
		display: none;
	}
  .js-intersection_flag--active &{
    opacity: 0;
    pointer-events: none;
  }
}
