@use 'css/abstracts' as *;
.o-menuNavwrap {
	display: none;
	overflow-x: hidden;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 999;

	background: #fff;
	height: 100vh;
    height: 100lvh;
	padding: 104px 48px 0;
	width: 100vw;

    .is-active &{
        display: block;
    }
	@media (min-width: 1200px) {
		display: none !important;
	}
}

.o-menuNav__item{
    & + .o-menuNav__item{
        margin-top: 32px;
    }

}
.o-menuNav__link {
	position: relative;
	padding-left: 24px;

  color: var(--black, #1C1F1E);
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Noto Sans JP;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 150%; /* 24px */
letter-spacing: 0.8px;
	&::before {
		content: '';
		position: absolute;
		left: 0;
        top: 0;
        bottom: 0;
		margin: auto;
		display: block;
		width: 12px;
		height: 12px;
		background-repeat: no-repeat;
		background-size: contain;
		background-image: url(../img/top/icon_arrow2.svg);
	}
}
