@use "css/funcion" as *;

:root {
  // Colors
  /// Primitive
  --global-color-white: #fff;
  // Black
  --global-color-black: #1C1F1E; // body
  --global-color-black-400: #282828; // .a-btn.-darkGray
  --global-color-black-300: #333; // .o-footerNav__item:nth-child(odd) .o-footerNav__link:before
  // Gray
  --global-color-gray-600: #5c5c5c; // .a-btn.-gray, .o-footerNav__link
  --global-color-gray-500: #666; // .o-heroCarousel .swiper-pagination-bullet
  --global-color-gray-400: #A1A1A1; // .a-btn.-gray, .o-footerNav__link
  --global-color-gray-350: #D9D9D9; // .o-formBlockProgressStepBar
  --global-color-gray-300: #e5e5e5; // .o-fittingAnswerContent__cityInner
  --global-color-gray300: #A1A1A1; // .o-fittingAnswerContent__cityInner
  --global-color-gray200: #F1F1F1; // .o-fittingAnswerContent__cityInner
  //--global-color-gray-250: #e9e9e9; // .m-jobInfoCard__footer
  --global-color-gray-200: #F1F1F1; // .p-home.t-hasSidebar, NEW: .m-formCheckboxCard
  --global-color-gray-100: #f5f5f5; // NEW: mypage-title-bgc
  // Red
  --global-color-red-400: #ED1C24; // .a-btn
  --global-color-red-350: #F3696E;
  --global-color-red-300: #F8A4A7; // .a-btn
  --global-color-red-250: #FCD1D3;
  --global-color-red-200: #FDE7E8; // NEW: フォームの警告色
  // beige
  --global-color-beige-400: #7A7159; //
  --global-color-beige-300: #B9A87B; //
  --global-color-beige-200: #C9AD5F; //
  --global-color-light-beige: #DFDDD6; //

  //orange
  --orange: #FFB800;



  // Typography
  --global-typography-fontSize-xs: #{rem(10)};
  --global-typography-fontSize-sm: #{rem(12)};
  --global-typography-fontSize-default: #{rem(14)};
  --global-typography-fontSize-lg: #{rem(16)};
  --global-typography-fontSize-xl: #{rem(18)};
  --global-typography-fontSize-xxl: #{rem(20)};
  --global-typofgraphy-heading-1: #{rem(24)};
  --global-typofgraphy-heading-2: #{rem(20)};
  --global-typofgraphy-lineHeight-sm: 1.4;
  --global-typofgraphy-lineHeight: 1.5;
  --global-typofgraphy-lineHeight-lg: 1.8;
  --global-typofgraphy-lineHeight-xl: 2;
  /// Color
  --global-color-primary: #0B2945;
  --global-color-primary-contract: var(--global-color-white);
  --global-color-secondary: #0060B8;
  --global-color-text: var(--global-color-black);
  --global-color-text-contract: var(--global-color-white);
  --global-color-border: #ccc;
  --global-color-warn: #ED1C24;
  --global-color-warn-contract: var(--global-color-red-200);
  // Background
  --global-background-color: var(--global-color-gray-100);
  // gradient
  --global-gradation_gold: linear-gradient(93.28deg, #96855A 1.75%, #947A35 37.67%, #CAAC5D 50.03%, #A68C47 61.39%, #827041 99.83%);
  --global-gradation_gold2:  linear-gradient(93.28deg, #AE9553 1.75%, #CFBA83 23.2%, #B99B4B 99.83%);
  // Spacing
  /// Primitive
  --global-spacing-0: 0;
  --global-spacing-1px: 1px;
  --global-spacing-0-5: 2px;
  --global-spacing-1: 4px;
  --global-spacing-1-5: 6px;
  --global-spacing-2: 8px;
  --global-spacing-2-5: 10px;
  --global-spacing-3: 12px;
  --global-spacing-4: 16px;
  --global-spacing-5: 20px;
  --global-spacing-6: 24px;
  --global-spacing-7: 28px;
  --global-spacing-8: 32px;
  --global-spacing-9: 36px;
  --global-spacing-10: 40px;
  --global-spacing-20: 80px;
  // Domain
  --global-ui-disabled-opacity: .5;
  --global-ui-hover-opacity: .5;
  --global-ui-animation-speed: .2s; // $animationSpeed
  /// 1〜9 Atom
  /// 10〜19 Molecules
  /// 20〜29 Organisms
  /// 30〜39 Template
  /// 40〜49 Page
  /// 50〜59 position: fixed;
  /// 100〜 Must on the front UI.
  $z-index_header: 100;
  $z-index_drawer: 59;
  $z-index_p-form-floatWidget: $z-index_drawer - 1;
  $z-index_p-secondary-job-SearchModalButton: $z-index_drawer - 1;
  $z-index_p-secondary-job-bottomAnchorLink: $z-index_drawer - 1;
  $z-index_p-search-globalSearchButton: $z-index_drawer - 1;
  $z-index_p-search-refineStillOfferButton: $z-index_drawer - 2; // secondary-jobで下部固定を上に出すため
  $z-index_p-form-form-toSearchAction: $z-index_drawer - 1;
  $z-index_p-form-form-fixedLinkBanner: $z-index_drawer - 1;
  $z-index_global-fixed-viewport-bottom: $z-index_p-form-floatWidget - 1;
  $z-index_global-fixed-viewport-bottom-secondary: $z-index_global-fixed-viewport-bottom - 1;
  //sidebarよりも上に表示させる
  $z-index_p-step-footerLink: 40;
  --global-z-index-header: #{$z-index_header};
  --global-z-index-drawer: #{$z-index_drawer};

  /// Grid
  /// @note CSS Variablesが使えれば、:rootに配置した上でJSからはそれを取得して使える・・・
  //$SITE-GARTER-OUTER--SP: 15;
  //$SITE-GARTER-OUTER: 60; // @note 大枠の外側
  --global-gutter-outer-xs: 15px;
  --global-gutter-outer-md: 60px;
  /// Contianer
  //$SITE-CONTENT-INNER-WIDTH--SP: 330; // @note あくまでデザインの最小サイズからの値
  //$SITE-CONTENT-INNER-WIDTH: 1080;
  //$SITE-CONTENT-WIDTH: $SITE-CONTENT-INNER-WIDTH + $SITE-GARTER-OUTER * 2;
  //--global-container-inner-w-xs: 330px;
  --global-container-inner-w-md: 1080px;
  --global-container-w: calc(var(--global-gutter-outer-md) * 2 + var(--global-container-inner-w-md));
}
