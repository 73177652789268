@use './root';
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}
body {
	line-height: 1;
}
ol,
ul {
	list-style: none;
}
blockquote,
q {
	quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
img {
	display: block;
	width: 100%;
	height: auto;
}

body {
	font-family: 'Noto Sans JP', sans-serif;
	color: var(--global-color-black);
	&.is-active {
		//menu open時のスクロールを禁止
		overflow: hidden;
	}
}
a {
	color: inherit;
	text-decoration: none;
}

.font-gradation {
	&.-gold {
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
			linear-gradient(
				93.28deg,
				#96855a 1.75%,
				#947a35 37.67%,
				#caac5d 50.03%,
				#a68c47 61.39%,
				#827041 99.83%
			);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
		text-fill-color: transparent;
	}
	&.-gold2 {
		background: linear-gradient(
			93.28deg,
			#ae9553 1.75%,
			#cfba83 23.2%,
			#b99b4b 99.83%
		);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
		text-fill-color: transparent;
	}
	&.-gold3 {
		background: linear-gradient(
			93.92deg,
			#786432 -12.67%,
			#9a8449 27.55%,
			#88784e 106.2%
		);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
		text-fill-color: transparent;
	}
}
.-bg_green {
	border-radius: 0px 48px 0px 0px;
	background: var(--bg-green, #f3f9f5);
	@media (min-width: 769px) {
		border-radius: 0px 120px 0px 0px;
	}
}
.-bg_green-2 {
	border-radius: 0px 48px 0px 0px;
	background: #E8F6EE;
	@media (min-width: 769px) {
		border-radius: 0px 120px 0px 0px;
	}
}

.site-container {
	position: relative;
}
.site-container__inner {
	width: 100%;
	// max-width: 1440px;
	max-width: 1240px;
	margin: auto;
	margin-top: 120px;
	margin-bottom: 136px;
	padding: 0 20px;
	@media (min-width: 769px) {
		padding: 0 80px;
	}
}
.br-pc {
	display: none;
	@media (min-width: 769px) {
		display: block;
	}
}
.br-sp {
	display: block;
	@media (min-width: 769px) {
		display: none;
	}
}

.o-bg_belt {
	display: block;
	height: 128px;
	img {
		height: 100%;
		// width: auto;
		object-fit: cover;
		object-position: bottom;
	}
	@media (min-width: 769px) {
		height: 256px;
		img {
			width: 100%;
		}
	}
}


.m-sct_title {
  color: var(--emeralds-green, #00c992);
  font-family: Albert Sans;
  font-size: 48px;
  font-style: normal;
  font-weight: 100;
  line-height: 1; /* 80px */
  text-align: center;
  @media (min-width: 769px) {
    font-size: 80px;
  }
}
.m-sct_title_jp {
  color: var(--black, #1c1f1e);
  text-align: center;
  font-family: Noto Sans JP;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5; /* 36px */
  margin-top: 8px;
  @media (min-width: 769px) {
    font-size: 28px;
  }
}
.sec-description {
  color: var(--black, #1c1f1e);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 32.4px */
  margin-top: 32px;
  @media (min-width: 769px) {
    margin-top: 48px;
  }
}
