@use "sass:math";
@use "css/funcion" as *;
@use "css/mixin/core" as *;
@use "css/vendors" as *;

@mixin form-placeholder {
  @include placeholder-initialize(var(--global-form-color-placeholder));
}

@mixin form-visibility-hidden {
  @include visibility-hidden;
}

@mixin form-outline-initialize {
  outline: 1px solid var(--_outline-color, var(--global-form-color-text));
}

/**
  全てのフォームコンポーネントの共通機能
  inputに直接付与すること
 */
@mixin form-element-initialize-variables {
  // 外向きの変数
  --color-primary: var(--global-form-color-text);
  --color-secondary: var(--global-form-color-text-contract);
  --color-accent: var(--global-form-color-text); // > とか向け
  // ローカル
  --_bg-color: var(--color-secondary);
  --_bd-color: var(--color-secondary);
  --_color: var(--color-primary);
  --_outline-color: var(--color-primary);
  --_fz: var(--global-form-typofgraphy-fontSize);

  // コンポーネントがエラーを持っている場合(サーバーサイドで使用)
  &.-has-error,
  .-has-error {
    --color-primary: var(--global-form-color-warn);
    --color-secondary: var(--global-form-color-warn-contract);
    & + .a-formSelect__icon {
    background-image: url(../img/common/icon_arrow_select_err.svg);
  }
  }

  &[readonly] {
    opacity: var(--global-form-state-readonly-opacity);
  }
  &[disabled] {
    cursor: auto;
    opacity: var(--global-form-state-disabled-opacity);
  }
  

}

@mixin form-component-initialize-style {
  @include form-placeholder;
  font-size: var(--global-form-typofgraphy-fontSize);
  line-height: var(--global-form-typofgraphy-lineHeight);
  color: var(--_color);
  outline-color: var(--_outline-color);
  font-family: inherit;
}

/**
  input,textarea,select
 */
@mixin form-input-component-initialize {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid var(--_bd-color);
  background-color: var(--_bg-color);
  padding: 10px 20px;
  font-weight: 700; // NOTE

  &:focus-visible {
    @include form-outline-initialize;
  }


  //&:not(:empty):required:invalid,
  &:invalid {
    --color-primary: var(--global-color-warn);
    --color-secondary: var(--global-color-warn-contract);
  }

  &:placeholder-shown:invalid {
    --color-primary: var(--global-form-color-text);
    --color-secondary: var(--global-form-color-text-contract);
  }
}

@mixin form-select-component-initialize {
  border: 0;
  background-color: var(--_bg-color);
  font-weight: 700;
  color: var(--_color); // NOTE 将来的には、:hasを使えば select:has(option:first-child)で、1つ目の選択肢を選んでいる状態を表人できる

  // select[required]の場合、value=""が選択されている場合はinvalidになる
  &:not(:focus-visible):invalid {
    //--color-primary: var(--global-color-warn);
    //--color-secondary: var(--global-color-warn-contract);
    --_bg-color: var(--color-secondary);
  }

  //& > option:first-child,
  //&:invalid {
  //  color: var(--global-form-color-placeholder);
  //}

  & > option {
    color: var(--_color);

    &[value=""],
    &:first-child {
      color: var(--global-form-color-placeholder);
    }
  }
}

@mixin form-checkbox-checked-variables() {
  --color-primary: var(--global-form-color-active);
  --color-secondary: var(--global-form-color-active-contract);
  --_outline-color: var(--color-primary);
}

@mixin form-focus-within {
  &:focus-within {
    --_bd-color: var(--_bg-color);
  }
}

@mixin form-component-label-initialize() {
  @include initialize-form-component-typography;
  font-weight: 400;
}

@mixin _initialize-form-component-typography-base() {
  //overflow-wrap: break-word; // @see https://developer.mozilla.org/ja/docs/Web/CSS/CSS_Text/Wrapping_Text#%E9%95%B7%E3%81%84%E5%8D%98%E8%AA%9E%E3%81%AE%E5%88%86%E5%89%B2
  word-break: break-all;
}

@mixin initialize-form-component-typography() {
  @include _initialize-form-component-typography-base;
  font-size: var(--global-form-typofgraphy-fontSize);
  line-height: var(--global-form-typofgraphy-lineHeight);
}

@mixin initialize-form-component-typography-small() {
  @include _initialize-form-component-typography-base;
  font-size: var(--global-form-typofgraphy-fontSize-sm);
  line-height: var(--global-form-typofgraphy-lineHeight-lg);
}


@mixin initialize-form-component-typography-xs() {
  @include _initialize-form-component-typography-base;
  font-size: var(--global-form-typofgraphy-fontSize-xs);
  line-height: var(--global-form-typofgraphy-lineHeight);
}

@mixin initialize-form-input-block-grid-gap {
  gap: var(--global-form-element-input-gap-y) var(--global-form-element-input-gap-x);
 .p-form-confirm &{
  gap: var(--global-form-element-input-gap-y) 0;
    
  }
  @content;
}

@mixin form-breakpoint-extra-small-up {
  @include mq(xs) {
    @content;
  }
}

@mixin form-breakpoint-extra-small-down {
  @include mq($until: xs) {
    @content;
  }
}

@mixin form-breakpoint-narrow-up {
  @include mq(md) {
    @content;
  }
}

@mixin form-breakpoint-narrow-down {
  @include mq($until: md) {
    @content;
  }
}

@mixin form-breakpoint-up {
  @include mq($from: lg) {
    @content;
  }
}

@mixin form-breakpoint-down {
  @include mq($until: lg) {
    @content;
  }
}

/**
GMJのフォームの補助テキストは、基本的に「下揃え」
 */
@mixin form-layout-block-prefix-input-suffix-container(
  $align-items: flex-end
) {
  display: flex;
  gap: var(--global-form-element-input-gap-x);
  align-items: $align-items;
}

@mixin form-component-checkbox-disabled-style {
  &.-is-disabled > label,
  & > [type="checkbox"]:disabled + label {
    cursor: auto;
    opacity: var(--global-form-state-disabled-opacity);
  }
}

@mixin form-complete-checkboxButton-height-style {
  // h32pxに揃える
  //height: 2rem;
  height: auto; // NOTE 折り返し対応
}

@mixin form-stack-container-row-breakpoint-up-common-min-height {
  @include form-breakpoint-up {
    min-height: var(--global-form-element-min-height);
  }
}
@mixin form-stack-container-row-breakpoint-up-common-min-height-remove {
  @include form-breakpoint-up {
    min-height: 0;
  }
}

@mixin formBlockContainer-modifier {
  & > [class*="a-form"] {
    max-width: 100%;
    width: 100%;
  }

  &.-h-narrow {
    min-height: 0;
  }

  &.-w-xs {
    @include form-breakpoint-up {
      --_maxw: 80px;
    }
  }
  &.-w-sm {
    @include form-breakpoint-up {
      --_maxw: 240px;
    }
  }

  &.-w-md {
    @include form-breakpoint-up {
      --_maxw: 496px;
    }
  }
  &.-w-md2 {
    @include form-breakpoint-up {
      --_maxw: 424px;
    }
  }

  &.-w-lg {
    @include form-breakpoint-up {
      --_maxw: 520px;
    }
  }

  &.-w-full {
    @include form-breakpoint-up {
      --_maxw: 100%;
    }
  }
}
